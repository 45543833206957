import { BrowserRouter as Router } from "react-router-dom"
import Layout from "./views/Layout/Layout"
import dotenv from 'dotenv'
import AuthProvider from "./store/AuthProvider";

import './App.css';

dotenv.config()

function App() {
  return (
    <Router>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </Router>
  );
}

export default App;
