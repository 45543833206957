import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../store/AuthProvider";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [formError, setFormError] = useState()
  const { login } = useLogin()

  const loginSubmit = async (event) => {
    event.preventDefault()

    let user
    try {
      const result = await fetch(`${process.env.REACT_APP_FAKEAPI_URL_LOCAL}/usuario.json?username=${username}&password=${password}`)
      user = await result.json()
    } catch (error) {
      
    }

    if (!user.length) {
      setFormError('Usuario ou contrasinal inválidos')
    } else {
      setFormError('')
      login(user[0])
      navigate('/privado')
    }
  }

  return (
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6">
            <form id="loginform" onSubmit={loginSubmit} >
              <div className="form-group">
                <label><h3>ASOCIADO</h3></label>
                <input
                  type="username"
                  className="form-control"
                  name="UsernameInput"
                  placeholder="Asociado"
                  onChange={(event) => setUsername(event.target.value)}
                />
                <small id="usernameHelp" className="text-danger form-text">
                  {formError}
                </small>
              </div>
              <div className="form-group">
              <label><h3>CONTRASEÑA</h3></label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Contraseña"
                  onChange={(event) => setPassword(event.target.value)}
                />
                <small id="passworderror" className="text-danger form-text">
                  {formError}
                </small>
              </div>
              
              <button type="submit" className="btn btn-lg btn-circle btn-outline-new-white">
                <h1>ENTRAR</h1>
              </button>
            </form>
          </div>
        </div>
       
      </div>
      
 
  );
}
export default Login;
