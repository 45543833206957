import React, { useState, useEffect } from 'react'

const Homenews = () => {
    const [db, setDb] = useState()
    
    useEffect(() => {
      fetch('https://hostaleria-f3540-default-rtdb.europe-west1.firebasedatabase.app//noticias.json')
        .then(r => r.json())
        .then(data => setDb(data))
    }, [])
    if (!db) return 'Loading...'
    
    const novas2 = db.sort(function (a, b) {
      return (b.id - a.id)
  })
   const novas = novas2.slice(0,6)
  
  
    return (
      <div className="gallery-box">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-title text-center">
                <h2>NOTICIAS</h2>
                <p>Noticias de nuestra actividad y entorno</p>
              </div>
            </div>
          </div>
          <div className="tz-gallery">
      <div className="row">
        {novas.map(nova =>
          <div key={nova.id} className="col-sm-12 col-md-4 col-lg-4 novas">
            <a className="lightbox" href="/novas">
            <h1>{nova.titulo}</h1>
              <img className="novasimg" src={nova.imagen} alt="Gallery Images" loading='lazy'/>
              <h2>{nova.fecha}</h2>
            </a>
          </div>
        )}
      </div>
    </div>
        </div>
      </div>
    )
  }
  
  export default Homenews
  