import { createContext, useContext, useState } from "react";

export const AuthContext = createContext([null, () => {}])

export const useLogin = () => {
  const { user, login, logout } = useContext(AuthContext)

  return {
    user,
    login,
    logout
  }
}

export default function AuthProvider ({ children }) {
  const [user, setUser] = useState(null)

  const login = (loginData) => setUser(loginData)
  const logout = () => setUser(null)

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}