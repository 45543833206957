import Slider from '../../components/Slider/Slider';

import puente from '../../assets/images/portada/puente.jpg'
import panorama from '../../assets/images/portada/panorama.jpg'
import sondemar from '../../assets/images/portada/sondemarcarballo.jpg'
import fuegosGeni from '../../assets/images/portada/fuegosGeni.jpg'
import arcoIris from '../../assets/images/portada/arcoIris.jpg'
import carmen from '../../assets/images/portada/carmen.jpg'
import illa from '../../assets/images/portada/mNocheGeni.jpg'
import fraga from '../../assets/images/portada/fraga2Geni.jpg'
import axuntamentoMiguel from '../../assets/images/portada/concellomoaña1.jpg'
import ciescarballo from '../../assets/images/portada/moañaGeni.jpg'
import domaio from '../../assets/images/portada/domaioGeni.jpg'
import randeylunaMiguel from '../../assets/images/portada/randeylunaMiguel.jpg'
import fisgonGeni from '../../assets/images/portada/fisgonGeni.jpg'


const HomeSlider = () => {
  const title = "ASOCIACIÓN HOSTALEIROS DE MOAÑA"
  const subtitle = <>
    {/* <h2>Traballando para darche o mellor servicio</h2>
    <h2>Todo o día pendente de ti</h2> */}
  </>
  const slides = [
    {
      title,
      subtitle,
      image: panorama
    },
    {
      title,
      subtitle,
      image: fisgonGeni
    },
    {
      title,
      subtitle,
      image: arcoIris
    },
    {
      title,
      subtitle,
      image: puente
    },
    {
      title,
      subtitle,
      image: sondemar
    },{
      title,
      subtitle,
      image: carmen
    },
    {
      title,
      subtitle,
      image: fuegosGeni
    },
    {
      title,
      subtitle,
      image:fraga
    },
    {
      title,
      subtitle,
      image: illa
    },
    {
      title,
      subtitle,
      image: axuntamentoMiguel
    },
    {
      title,
      subtitle,
      image: ciescarballo
    },
    {
      title,
      subtitle,
      image: domaio
    },
    {
      title,
      subtitle,
      image: randeylunaMiguel
    }

  ]

  return <Slider slides={slides} />

}

export default HomeSlider