import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PlantillaSlider from "./PlantillaSlider"
// import PlantillaGallery from './plantillaGallery'
import QTplantilla from './QTplantilla'





const Plantilla = () => {

  const { dominio } = useParams()
  const [db, setDb] = useState()
  useEffect(() => {
    fetch(`${process.env.REACT_APP_FAKEAPI_URL_LOCAL}/partners.json`)
      .then(r => r.json())
      .then(data => setDb(data))
  }, [])

  if (!db) return 'loading...'
  const entries = db.filter(i => i.dominio === dominio)
  if (!entries.length) return 'no existe ninguna oferta en esta actividad'


  const asociado = entries[0]

  return (
    <>
      <div className="plantilla">
        <PlantillaSlider />
      </div>
      <div className="about-section-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img src={asociado.logo} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
              <div className="inner-column">
              <h2> <strong>{asociado.tipo}</strong></h2>
                <h1> <span>{asociado.nombre}</span></h1>    
                <h2> {asociado.frase}</h2>
                <h2>DIRECCION: {asociado.direccion}</h2>
                {asociado.email && <h2>CORREO ELECTRÓNICO: {asociado.email}</h2>}
                <h3>
                  <a href={`tel:+34${asociado.telefono}`}>
                    <i className="fa fa-volume-control-phone"></i>
                    TELEFONO: {asociado.telefono}
                  </a>
                </h3> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div classname="qtplantilla">
      <QTplantilla/>
      </div>
      <div className="about-section-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
              <div className="inner-column">
                <h1> COMO LLEGAR </h1>
                <iframe title="coordenadas" src={asociado.llegar} width={400} height={300} style={{ border: 100 }} allowfullscreen={""} loading={"lazy"}></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <div className="inner-column">
              <h1>SERVICIOS </h1>
              <div className="inner-column">
                {asociado.servicio1 && <h2>{asociado.servicio1}</h2>}
                {asociado.servicio2 && <h2>{asociado.servicio2}</h2>}
                {asociado.servicio3 && <h2>{asociado.servicio3}</h2>}
                {asociado.servicio4 && <h2>{asociado.servicio4}</h2>}
                {asociado.servicio5 && <h2>{asociado.servicio5}</h2>}
                {asociado.servicio6 && <h2>{asociado.servicio6}</h2>}
                {asociado.QR && <a href={asociado.QR} target="_blank" rel="noreferrer">  <img src={asociado.menu} alt="" className=""></img></a>}     
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <div className="inner-column">
                <h1> REDES </h1>
                <div className="inner-column">
                  {asociado.facebook && <a href={asociado.facebook} target="_blank" rel="noreferrer"> <i class="fa fa-facebook" aria-hidden="true"></i></a>}
                  {asociado.instagram && <a href={asociado.instagram} target="_blank" rel="noreferrer">  <i class="fa fa-instagram" aria-hidden="true"></i></a>}
                  {asociado.google && <a href={asociado.google} target="_blank" rel="noreferrer">  <i class="fa fa-google" aria-hidden="true"></i></a>}
                  {asociado.twitter && <a href={asociado.twitter} target="_blank" rel="noreferrer"> <i class="fa fa-twitter" aria-hidden="true"></i></a>}
                  {asociado.web && <a href={asociado.web} target="_blank" rel="noreferrer"> WEB</a>}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
              <div className="inner-column">
                <h1> CURIOSIDADES</h1>
                <h1> </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
{/* <PlantillaGallery/> */}
    </>
  )
}

export default Plantilla