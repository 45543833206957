import PropTypes from 'prop-types';

const Item = ({ children, filter = '', className = '' }) => {
  return (
    <div className={`special-grid ${className} ${filter}`}>
      {children}
    </div>
  )
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
  filter: PropTypes.string,
  className: PropTypes.string
}

export default Item