import QTBackground from "../../assets/images/portada/palco.jpg"
import Breadcrumb from "../../components/QT/Breadcrumb"

const QTNovas = () => {
    return (
      <Breadcrumb
        text='NOTICIAS DE LA ASOCIACION' 
        imageURL={QTBackground}
      />
    )
  }  
  export default QTNovas