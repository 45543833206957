import QT from "../../components/QT/QT"

import QTBackground from "../../assets/images/portada/moaña-3.jpg"

const QTHome = () => {
  return (
    <QT
      text='"A menos que tengas un nivel de satisfacción entre tus clientes del 100%, debes mejorar "' 
      author="Michael Strahan"
      imageURL={QTBackground}
    />
  )
}

export default QTHome