import { useEffect } from 'react'
import PropTypes from 'prop-types'
import baguetteBox from 'baguettebox.js'

const Gallery = ({ images }) => {
  useEffect(() => {
    baguetteBox.run('.tz-gallery', {
      animation: 'fadeIn',
      noScrollbars: true
    });
  }, [])

  return (
    <div className="tz-gallery">
      <div className="row">
        {images.map((image, index) =>
          <div key={index} className="col-sm-12 col-md-4 col-lg-4 novas">
            <a className="lightbox" href={image}>
              <img className="img-fluid" src={image} alt="Gallery Images" loading='lazy'/>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

Gallery.propTypes = {
  images: PropTypes.array.isRequired
}

export default Gallery
