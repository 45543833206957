import PropTypes from 'prop-types';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'

// swiper core styles
import 'swiper/swiper.min.css'
// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

// import Swiper core and required modules
import SwiperCore, {
  EffectFade,Navigation,Pagination, Autoplay
} from 'swiper';

import './Slider.css'

// install Swiper modules
SwiperCore.use([EffectFade,Navigation,Pagination,Autoplay]);

const Slider = ({ slides }) => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        centeredSlides
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
      }}
        loop
        pagination={{
          "clickable": true,
        }}
        
        className="cover-slides"
      >
        {slides.map((slide, index) =>
          <SwiperSlide key={index}>
            <div className="caption">
              <h1 className="m-b-20">{slide.title}</h1>
              <h2>{slide.subtitle}</h2>
            </div>
            <img src={slide.image} alt="" loading='lazy' />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.node,
    image: PropTypes.string.isRequired
  })).isRequired
};

export default Slider
