import HomeSlider from "./HomeSlider"
import HomeAbout from "./HomeAbout"
import QTHome from "./QTHome"
import HomePartners from "./HomePartners"
import HomeReviewsCarousel from "./HomeReviewsCarousel"
import Homenews from "./homenews"


const Home = () => {
  return (
    <div className="home">
      <HomeSlider />
      <HomeAbout />
      <QTHome />
      <HomePartners />
      <Homenews />
      {/* <HomeGallery /> */}
      <HomeReviewsCarousel />
    </div>
  )
}

export default Home
