import { useContext } from "react"
import PropTypes from 'prop-types'
import { IsotopeFilterContext } from "./IsotopeLayout"

const Filter = ({ children, filter, className = "" }) => {
  const [selectedFilter, setFilter] = useContext(IsotopeFilterContext)
  const isSelected = selectedFilter === filter

  const handleClick = e => {
    e.preventDefault()
    setFilter(filter === '*' ? filter :`.${filter}`)
  }

  return (
    <button
      className={`${isSelected & 'active'} ${className}`}
      onClick={handleClick}
      data-filter={filter}
    >
      {children}
    </button>
  )
}

Filter.propTypes = {
  children: PropTypes.node.isRequired,
  filter: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Filter