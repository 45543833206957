import PropTypes from 'prop-types'



const Breadcrumb = ({ imageURL, text }) => {
    return (
      <div className="all-page-title page-breadcrumb" style={{ backgroundImage: `url(${imageURL})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-left">
              <p className="lead ">
                {text}
              </p>
            </div>
          </div>
        </div>
        </div>
    )
  }


Breadcrumb.propTypes = {
    imageURL: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }
  
  export default Breadcrumb