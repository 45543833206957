
import tapaOtono22_1 from '../../../assets/images/noticias/tapa_outono_2022/tapaOtono22_1.jpg'
import tapaOtono22_4 from '../../../assets/images/noticias/tapa_outono_2022/tapaOtono22_4.jpg'
import NovasTapasCarrousel from './novasTapasCarrousel'



const TapaOtono2022 = () => {
  return (
    <main className="about">
      <div className="about-section-box">
        <div className="container">
          <div className="col-md-12">
            <div className="inner-pt">
              <img src={tapaOtono22_1} alt="" className="img-fluid" />
             <div className="inner-column">
              Dende a ASOCIACIÓN DE HOSTALEIROS DE MOAÑA vos presentamos o primeiro concurso de hostelería, que se realizará entre o17 de octubre
                e o 30 de novembro. Nesta primeira edición participan 19 establecementos asociados divididos en 3 grupos:
                menú degustación, tapas, e cafetería/coctelería.<br></br>
              O menú degustación consta de 3 platos de productos de primeira calidade, 
                rematando con un postre orixinal dedicado ó outono<br></br>
               No grupo das tapas atoparedes moita variedade, cada establecemento creou unha tapa orixinal
                 con ingredientes otoñais<br></br>
              Por último o mais novedoso, Cafetería/Coctelería que espera conquistar o paladar con seus almorzos, 
                merendas e cocteles<br></br>
                A seguir vos queda unha lista de todos os locais participantes en cada especialidade, 
                  con unha pequena descripción do seu producto e os horarios nos que estarán dispoñibles.
                  Podedes visitar a páxina concreta de cada local pinchando sobre seu nome.
              
             </div>
              <div id="tapaotono1">
                <div className="menu" style={{ top: '10%' }}><a target="_blank" rel="noreferrer" alt="eladio" title="eladio" href="http://hostaleriamoaña.com/barEladio">...............................</a></div>
                <div className="menu" style={{ top: '24%' }}><a target="_blank" rel="noreferrer" alt="ayCarmela" title="ayCarmela" href="http://hostaleriamoaña.com/AyCarmela">.................................</a></div>
                <div className="menu" style={{ top: '38%' }}><a target="_blank" rel="noreferrer" alt="fabrica" title="fabrica" href="http://hostaleriamoaña.com/LaFabrica">.................................</a></div>
                <div className="menu" style={{ top: '52%' }}><a target="_blank" rel="noreferrer" alt="elPuerto" title="elPuerto" href="http://hostaleriamoaña.com/ArroceriaElPuerto">.....................................</a></div>
                <div className="menu" style={{ top: '66%' }}><a target="_blank" rel="noreferrer" alt="bathia" title="bathia" href="http://hostaleriamoaña.com/LaBathia">....................................</a></div>
                <div className="menu" style={{ top: '82%' }}><a target="_blank" rel="noreferrer" alt="pradoViejo" title="pradoViejo" href="http://hostaleriamoaña.com/">................................</a></div>
              </div>
              <div id="tapaotono2">
                <div className="menu" style={{ top: '7%' }}><a target="_blank" rel="noreferrer" alt="posito" title="posito" href="http://hostaleriamoaña.com/BarPosito">...............................</a></div>
                <div className="menu" style={{ top: '12%' }}><a target="_blank" rel="noreferrer" alt="aDeSandro" title="aDeSandro" href="http://hostaleriamoaña.com/ADeSandro">.................................</a></div>
                <div className="menu" style={{ top: '18%' }}><a target="_blank" rel="noreferrer" alt="adriano" title="adriano" href="http://hostaleriamoaña.com/ChurrasqueriaCasaAdriano">.................................</a></div>
                <div className="menu" style={{ top: '23%' }}><a target="_blank" rel="noreferrer" alt="piscis" title="piscis" href="http://hostaleriamoaña.com/Piscis">.....................................</a></div>
                <div className="menu" style={{ top: '27%' }}><a target="_blank" rel="noreferrer" alt="cibeles" title="cibeles" href="http://hostaleriamoaña.com/BarTaperiaLaCibeles">....................................</a></div>
                <div className="menu" style={{ top: '33%' }}><a target="_blank" rel="noreferrer" alt="equis" title="equis" href="http://hostaleriamoaña.com/BarEquis">................................</a></div>
                <div className="menu" style={{ top: '38%' }}><a target="_blank" rel="noreferrer" alt="muxicas" title="muxicas" href="http://hostaleriamoaña.com/MuxicasCervexariaVi%C3%B1oteca">...............................</a></div>
                <div className="menu" style={{ top: '52%' }}><a target="_blank" rel="noreferrer" alt="cafferia" title="cafferia" href="http://hostaleriamoaña.com/LaCafferia">.................................</a></div>
                <div className="menu" style={{ top: '59%' }}><a target="_blank" rel="noreferrer" alt="agora" title="agora" href="http://hostaleriamoaña.com/CafeteriaAgora">.................................</a></div>
                <div className="menu" style={{ top: '70%' }}><a target="_blank" rel="noreferrer" alt="oRosal" title="oRosal" href="http://hostaleriamoaña.com/PanaderiaORosal">.....................................</a></div>
                <div className="menu" style={{ top: '80%' }}><a target="_blank" rel="noreferrer" alt="fairies" title="fairies" href="http://hostaleriamoaña.com/TheFairies">....................................</a></div>
                <div className="menu" style={{ top: '88%' }}><a target="_blank" rel="noreferrer" alt="xardin" title="xardin" href="http://hostaleriamoaña.com/CafeteriaHeladeriaXardin">................................</a></div>
                <div className="menu" style={{ top: '94%' }}><a target="_blank" rel="noreferrer" alt="carriola" title="carriola" href="http://hostaleriamoaña.com/ACarriola">................................</a></div>
              </div>
              <div className="inner-column">
                E para agradecer a vosa colaboración neste concurso, imos facer un sorteo entre todos os participantes nas votacións
                e vos obsequiaremos con un gran premio en xeral e un premio por cada categoría como podedes ver na siguiente imaxen.
                Sólo tedes que rellenar os datos no voso voto que vos proporcionarán no propio local donde estades participando, 
                dar o vosa puntuación do producto que tomastes e moita sorte no concurso.
              </div>
              <img src={tapaOtono22_4} alt="" className="img-fluid" />            
            </div>
          </div>
        </div>
      </div>
      <NovasTapasCarrousel />
    </main>
  )
}



export default TapaOtono2022