import PropTypes from 'prop-types';

const Container = ({ children, className = '' }) => {
  return (
    <div className={`special-list ${className}`}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Container