import { Carousel, CarouselItem } from "../../components/Carousel"

import image1 from '../../assets/images/logoAbanca.jpeg'
import image2 from '../../assets/images/logoSoluzion.png'
import image3 from '../../assets/images/logoAbadia.png'

const HomeReviewsCarousel = () => {
  return (
    <div className="customer-reviews-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading-title text-center">
              <h2>COLABORORACIONES Y CONVENIOS</h2>
              <p>Presentación de las empresas que tienen convenio con nosotros o colaboran con la asociación</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 mr-auto ml-auto text-center">
            <Carousel id="reviews" indicators={false}>
              <CarouselItem>
                <div className="img-box p-1 border rounded-circle m-auto">
                  <img className="d-block w-100 rounded-circle" src={image1} alt="" />
                </div>
                <h5 className="mt-4 mb-0"><strong className="text-warning text-uppercase">ABANCA</strong>
                </h5>
                <h6 className="text-dark m-0">BANCO</h6>
                <p className="m-0 pt-3">En el año 2021 la asociación de hosteleros firmó un convenio con esta entidad bancaria, obteniendo mejoras para los asociados.</p>
              </CarouselItem>
              <CarouselItem>
                <div className="img-box p-1 border rounded-circle m-auto">
                  <img className="d-block w-100 rounded-circle" src={image2} alt="" />
                </div>
                <h5 className="mt-4 mb-0"><strong className="text-warning text-uppercase">SOLUZION</strong>
                </h5>
                <h6 className="text-dark m-0">ENERXIAS</h6>
                <p className="m-0 pt-3">Empresa ubicada en Moaña, que asesora a la asociación de hosteleros con las facturas de luz, buscando el mejor precio entre varias compañías.</p>
              </CarouselItem>
              <CarouselItem>
                <div className="img-box p-1 border rounded-circle m-auto">
                  <img className="d-block w-100 rounded-circle" src={image3} alt="" />
                </div>
                <h5 className="mt-4 mb-0"><strong className="text-warning text-uppercase">ABADÍA</strong>
                </h5>
                <h6 className="text-dark m-0">ACADEMIA</h6>
                <p className="m-0 pt-3">Desde los inicios de la asociación de hosteleros , esta empresa asesora sobre los varios tipos de cursos que son necesarios en los negocios de hostelería.</p>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeReviewsCarousel