
import estatuamiguel from '../../assets/images/estatuamiguel.jpg'


const HomeAbout = () => {
  return (
    <div className="about-section-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={estatuamiguel} alt="" className="img-fluid" loading='lazy' />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <div className="inner-column">
              <h1>Bienvenidos a <span>HOSTELERIA DE MOAÑA</span></h1>
             
              <p>Somos la asociación hostelera de Moaña, fundada en 2020 a raíz de los problemas derivados de la pandemia mundial del covid-19</p>
              <p>Decidimos unirnos a esta asociación para que podamos ser más fuertes tanto entre nosotros como con nuestros clientes y crear las herramientas ideales para llegar mejor a usted.</p>
              <a href='/About' name="arriba" className="btn btn-lg btn-circle btn-outline-new-white">
                        SABER MAS
                     </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeAbout