import QT from "../../components/QT/QT"
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'








const QTplantilla = () => {

const { dominio } = useParams()
const [db, setDb] = useState()
useEffect(() => {
  fetch(`${process.env.REACT_APP_FAKEAPI_URL_LOCAL}/partners.json`)
    .then(r => r.json())
    .then(data => setDb(data))
}, [])

if (!db) return 'loading...'

const entries = db.filter(i => i.dominio === dominio)
if (!entries.length) return 'no existe ninguna oferta en esta actividad'
console.log(entries)

const asociado = entries[0]

  return (
    <QT
      text={asociado.fraseFilosofia}
    
      imageURL={asociado.imagenscroll}
    />
  )
}

export default QTplantilla