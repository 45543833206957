import QTBackground from "../../assets/images/privateArea2.jpg"
import Breadcrumb from "../../components/QT/Breadcrumb"

const QTPrivado = () => {
    return (
      <Breadcrumb
        text='"ZONA PRIVADA DE LOS ASOCIADOS"' 
        imageURL={QTBackground}
        
      />
      
    )
  }
  
  export default QTPrivado