
import QTBackground from "../../assets/images/portada/lua.jpg"
import Breadcrumb from "../../components/QT/Breadcrumb"

const QTPartners = () => {
    return (
      <Breadcrumb
        text='"LISTADO DE ASOCIADOS"' 
        imageURL={QTBackground}
      />
    )
  }
  
  export default QTPartners