import estatuamiguel from '../../assets/images/estatuamiguel.jpg'
import AboutGallery from './AboutGallery'
import QTAbout from './BreadcrumAbout'





const About = () => {
  return (
    <main className="about">
      <QTAbout />
      <div className="about-section-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img src={estatuamiguel} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
              <div className="inner-column">
                <h1>Bienvenidos a <span>HOSTELERIA DE MOAÑA</span></h1>
                <p>Somos la asociación hostelera de Moaña, fundada en 2020 a raíz de los problemas derivados de la pandemia mundial del covid-19 </p>
                <p>Decidimos unirnos a esta asociación para que podamos ser más fuertes tanto entre nosotros como con nuestros clientes y crear las herramientas ideales para llegar mejor a usted.</p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="inner-pt">
              <p>A raíz de las  concentraciones y protestas producidas en las reivindicaciones a las autoridades competentes,
              nos encontramos bastantes hosteleros con las mismos problemas e inquietudes y de esas concentraciones surgió la idea de juntarnos en una asociación para así tener una voz mas fuerte y unida que se hiciera oir mas alto.
              </p>
              <p>En Moaña se produjo la creación de un grupo en las redes sociales en el cuál se decidió hacer una reunión y poder explicar nuestras inquietudes y dudas, llegándose a la conclusión de juntarnos en una asociación que aglutinara el sentir de todos y hablar en una sola voz. Después de varias reuniones se fundó esta asociación sin ánimo de lucro en la cuál podamos ponernos de acuerdo y trabajar juntos por un bien común, tanto el nuestro como el de nuestros clientes.
              </p>
              <p>En la actualidad, somos mas de 60 negocios en la asociación, más del 80% de los locales de hostelería de Moaña, con el objetivo de hacer posible una mejor comunicación entre nosotros, aprovechar la unión para conseguir mejoras para nuestros asociados, y así trasladarlo a nuestra clientela</p>
              <a href='/tapa_otoño_22 ' name="arriba" className="btn btn-lg btn-circle btn-outline-new-white">
                        TAPA OTOÑO 22
                     </a>
            </div>
          </div>
        </div>
      </div>
      <AboutGallery />
    </main>
  )
}

export default About
