import Gallery from "../../components/Gallery/Gallery"

import image1 from '../../assets/images/folletotapas.jpg'
import image2 from '../../assets/images/ManiMadrid.jpg'
import image3 from '../../assets/images/ManiMoaña.jpg'
import image4 from '../../assets/images/ConveAbanca.jpg'
import image5 from '../../assets/images/ReuConcello.jpg'
import image6 from '../../assets/images/ciescarballo.jpg'

const AboutGallery = () => {
  return (
    <div className="gallery-box">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading-title text-center">
              <h2>Galeria</h2>
              <p>imágenes de nuestra historia</p>
            </div>
          </div>
        </div>
        <Gallery images={[image1, image2, image3, image4, image5, image6]}/>
      </div>
    </div>
  )
}

export default AboutGallery