
import React from "react";
import { Container, IsotopeLayout, Filter, Item } from "../../components/Isotope"
import { Link } from 'react-router-dom'

import image1 from '../../assets/images/iconosAsociados/restaurante.jpg'
import image2 from '../../assets/images/iconosAsociados/taperia.jpg'
import image3 from '../../assets/images/iconosAsociados/burguer.jpg'
import image4 from '../../assets/images/iconosAsociados/pasteleria.jpeg'
import image5 from '../../assets/images/iconosAsociados/pizza1.jpg'
import image6 from '../../assets/images/iconosAsociados/dormir1.jpg'
import image7 from '../../assets/images/iconosAsociados/cerveza.jpg'
import image8 from '../../assets/images/iconosAsociados/copas.jpeg'
import image9 from '../../assets/images/iconosAsociados/cafesrec2.jpeg'
import image10 from '../../assets/images/iconosAsociados/vinos.jpg'
import image11 from '../../assets/images/iconosAsociados/casarural1.jpg'
import image12 from '../../assets/images/iconosAsociados/hostal.jpg'
import image13 from '../../assets/images/iconosAsociados/pension1.jpg'
import image14 from '../../assets/images/iconosAsociados/asador.jpg'




const HomePartners = () => {

  

  return (
    <div className="menu-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading-title text-center">
              <h2>ASOCIADOS</h2>
              <p>Directorio de nuestros asociados para que se pueda encontrarnos y visitar la información particular de cada uno de nuestros asociados</p>
              <p>Aqui las imagenes a modo de iconos para reconocer cada tipo de local</p>
            </div>
          </div>
        </div>
        <IsotopeLayout>
          <div className="row">
            <div className="col-lg-12">
              <div className="special-menu text-center">
                <div className="filter-button-group button">
                  
                  <Filter filter="*">TODOS</Filter>
                  <Filter filter="comer">COMER</Filter>
                  <Filter filter="beber">BEBER</Filter>
                  <Filter filter="dormir">DORMIR</Filter>
                </div>
              </div>
            </div>
          </div>
          <Container className="row">
          <Item className="col-lg-4 col-md-6" filter="beber">
              <div className="gallery-single fix">
                <img src={image9} className="img-fluid"  loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h3>CAFETERIA</h3>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>Seccion BEBER</h5>
                </a>
               <Link to="/partners"><h1 className="nombrebar">CAFETERIA</h1></Link> 
              </div>
            </Item>
            <Item className="col-lg-4 col-md-6" filter="comer">
              <div className="gallery-single fix">
                <img src={image4} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>CAFE-PASTELERIA</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion COMER</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">CAFE-PASTELERIA</h1></Link> 
              </div>
            </Item> 
            <Item className="col-lg-4 col-md-6" filter="beber">
              <div className="gallery-single fix">
                <img src={image7} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>CERVECERIAS</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion BEBER</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">CERVECERIA</h1></Link>                               
              </div>
            </Item> 
            <Item className="col-lg-4 col-md-6" filter="dormir">
              <div className="gallery-single fix">
                <img src={image11} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>CASAS RURALES</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion DORMIR</h5>
                </a>            
                <Link to="/partners"><h1 className="nombrebar">CASA RURAL</h1></Link>               
              </div>
            </Item>  
            <Item className="col-lg-4 col-md-6" filter="comer">
              <div className="gallery-single fix">
                <img src={image3} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>HAMBURGUESERIAS</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion COMER</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">HAMBURGUESERIA</h1></Link>               
              </div>
            </Item>
            <Item className="col-lg-4 col-md-6" filter="dormir">
              <div className="gallery-single fix">
                <img src={image12} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>HOSTALES</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion DORMIR</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">HOSTAL</h1></Link>                              
              </div>
            </Item>
            <Item className="col-lg-4 col-md-6" filter="dormir">
              <div className="gallery-single fix">
                <img src={image6} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>HOTELES</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion DORMIR</h5>
                </a>                
                <Link to="/partners"><h1 className="nombrebar">HOTEL</h1></Link>                           
              </div>
            </Item>
            <Item className="col-lg-4 col-md-6" filter="dormir">
              <div className="gallery-single fix">
                <img src={image13} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>PENSIONES</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion DORMIR</h5>
                </a>               
                 <Link to="/partners"><h1 className="nombrebar">PENSION</h1></Link>                              
              </div>
            </Item>            
            <Item className="col-lg-4 col-md-6" filter="comer">
              <div className="gallery-single fix">
                <img src={image5} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>PIZZERIAS</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion COMER</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">PIZZERIA</h1></Link>                              
              </div>
            </Item>  
            <Item className="col-lg-4 col-md-6" filter="beber">
              <div className="gallery-single fix">
                <img src={image8} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>BAR DE COPAS Y PUBS</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion BEBER</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">BAR COPAS-PUBS</h1></Link>                             
              </div>
            </Item>      
            <Item className="col-lg-4 col-md-6" filter="comer">
              <div className="gallery-single fix">
                <img src={image1} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>RESTAURANTE</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion COMER</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">RESTAURANTE</h1></Link>                               
              </div>
            </Item>
            <Item className="col-lg-4 col-md-6" filter="comer">
              <div className="gallery-single fix">
                <img src={image2} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>TAPERIAS</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion COMER</h5>
                </a>
                <Link to="/partners"><h1 className="nombrebar">TAPERIA</h1></Link>                             
              </div>
            </Item>
            <Item className="col-lg-4 col-md-6" filter="beber">
              <div className="gallery-single fix">
                <img src={image10} className="img-fluid" loading="lazy" alt="" />						
                <a href="/partners" className="why-text">
                  <h4>VINOS</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion BEBER</h5>
                </a>
                <Link to="/partners"> <h1 className="nombrebar">VINOS</h1></Link>                             
              </div>
            </Item> 
            <Item className="col-lg-4 col-md-6" filter="comer">
              <div className="gallery-single fix">
                <img src={image14} className="img-fluid" loading="lazy" alt="" />						
                <Link to="/partners" className="why-text">
                  <h4>ASADOR</h4>
                  <h5>Encuentralos en nuestro directorio general con esta imagen en</h5>
                  <h5>seccion COMER</h5>
                  </Link> 
                <Link to="/partners"> <h1 className="nombrebar">ASADOR</h1></Link>                             
              </div>
            </Item>               
          </Container>
        </IsotopeLayout>
        
       
      </div>
    </div>
  )
}

export default HomePartners