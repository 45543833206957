import PropTypes from 'prop-types'

const QT = ({ imageURL, text, author = "" }) => {
  return (
    <div className="qt-box qt-background" style={{ backgroundImage: `url(${imageURL})` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 ml-auto mr-auto text-left">
            <p className="lead ">
              {text}
            </p>
            <span className="lead">{author}</span>
          </div>
        </div>
      </div>
	  </div>
  )
}

QT.propTypes = {
  imageURL: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  author: PropTypes.string
}

export default QT