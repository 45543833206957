import React, { useState, useEffect } from 'react'

import QTNovas from "./QTNovas"


const Novas = () => {

  const [db, setDb] = useState()
  useEffect(() => {
    fetch('https://hostaleria-f3540-default-rtdb.europe-west1.firebasedatabase.app//noticias.json')
    .then(r => r.json())
      .then(data => setDb(data))
  }, [])

  if (!db) return 'Loading...'

  const novas2 = db.sort(function (a, b) {
    return (b.id - a.id)
})
 

  return (
    <main className="noticias">
      <QTNovas />
    
        {novas2.map(nova =>
          <div key={nova.id} className="nova" filter={nova.filter}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <img src={nova.imagen} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                <div className="inner-column">
                  <h1>{nova.titulo}</h1>
                  <h2>{nova.fecha}</h2>
                  <p>{nova.contenido}</p>                 
                  <a
                    className="btn btn-lg btn-circle btn-outline-new-white"
                    href={nova.enlace}
                    target="_blank" rel="noreferrer">
                    Enlace a prensa
                  </a>
                </div>
              </div>
            </div>
          </div>)}
     

    </main>
  )
}

export default Novas
