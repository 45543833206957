import PropTypes from 'prop-types';
import Isotope from 'isotope-layout'
import { useEffect, createContext, useState, useRef } from 'react';

export const IsotopeFilterContext = createContext(['', () => {}])

const IsotopeLayout = ({ children }) => {
  const container = document.querySelector('.special-list');
  const [filter, setFilter] = useState('*')
  const iso = useRef()

  useEffect(() => {
    if (!container || iso.current) return
    iso.current = new Isotope(container, {
      itemSelector: '.special-grid'
    });
  }, [container])

  useEffect(() => {
    if (!iso.current) return
    iso.current.arrange({
      filter
    })
  }, [filter])

  return (
    <IsotopeFilterContext.Provider value={[filter, setFilter]}>
      {children}
    </IsotopeFilterContext.Provider>
  )
}

IsotopeLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default IsotopeLayout