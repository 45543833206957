import { Container, IsotopeLayout, Filter, Item } from "../../components/Isotope"
import React, { useState, useEffect } from 'react'
import QTPartners from './QTPartners'



const Partners2 = () => {

  const [db, setDb] = useState()
  useEffect(() => {
    fetch('https://hostaleria-f3540-default-rtdb.europe-west1.firebasedatabase.app//partners.json')
    .then(r => r.json())
          .then(data => setDb(data))
  }, [])
  
    if (!db) return 'merdiña...'
  
  
const partners = db.sort(function() { return Math.random() - 0.5 });

  return (
    <main className="Partners">
      <QTPartners />
      <div className="menu-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-title text-center">
                <h2>ASOCIADOS</h2>
                <p>Directorio de nuestros asociados para que puedas encontrarnos y visitar la información particular de cada uno</p>
              </div>
            </div>
          </div>
          <IsotopeLayout>
            <div className="row">
              <div className="col-lg-12">
                <div className="special-menu text-center">
                  <div className="button-group filter-button-group">
                    <Filter  filter="*">Todos</Filter>
                    <Filter filter="beber">Beber</Filter>
                    <Filter filter="comer">Comer</Filter>
                    <Filter filter="dormir">Dormir</Filter>
                  </div>
                </div>
              </div>
            </div>

            <Container className="row">
              {partners.map(partner =>
              partner.nombre &&
                <Item key={partner.id} className="col-lg-4 col-md-6" filter={partner.filter}>
                  <div className="gallery-single fix">
                    <img src={partner.icono} className="img-fluid" alt="" loading='lazy'/>
                    <a href={`/${partner.dominio}`} name="arriba" className="why-text">
                      <h4>{partner.nombre}</h4>
                      <h5>{partner.frase}</h5>
                      <h5>{partner.localizacion}</h5>
                    </a>
                    <a href={`/${partner.dominio}`} ><h1 className="nombrebar">{partner.nombre}</h1></a>
                  </div>
                </Item>)}
                
              </Container>
          </IsotopeLayout>
        </div>
      </div>

    </main>
  )
}

export default Partners2