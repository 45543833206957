
import QTBackground from "../../assets/images/penacarballo2.jpg"
import Breadcrumb from "../../components/QT/Breadcrumb"

const QTAbout = () => {
    return (
      <Breadcrumb
        text='"Conócenos"' 
        imageURL={QTBackground}
      />
    )
  }
  
  export default QTAbout