const Contact = () => {
  return (
    <div className="contact-imfo-box">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <i className="fa fa-volume-control-phone"></i>
            <div className="overflow-hidden">
            <h4>TELÉFONO</h4>
            <p>
            <a href="tel:+34698989820">+34 698 98 98 20</a>
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <i className="fa fa-envelope"></i>
            <div className="overflow-hidden">
              <h4>Email</h4>
              <p className="lead">
              <a href="mailto:hostelerosmoana@gmail.com">hostelerosmoana@gmail.com</a>
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <i className="fa fa-map-marker"></i>
            <div className="overflow-hidden">
						<h4>DIRECCION</h4>
              <p className="lead">
							c/Concepción Arenal nº50 bajo Moaña Pontevedra
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact